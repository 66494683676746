<template>
    <div class="home">
        <!-- 头部 -->
        <myHead></myHead>

        <!-- 表头 -->
        <div class="header">
            <div class="qx" @click="allSelectCilck">
                <div class="img">
                    <img v-show="allSelect" src="@/assets/icon/xuanz.png" alt="">
                    <img v-show="!allSelect" src="@/assets/icon/wxz.png" alt="">
                </div>
                <div class="text">全选</div>
            </div>
            <div class="sp">商品</div>
            <div class="sl">数量</div>
            <div class="je">总价</div>
            <div class="cz">操作</div>
        </div>

        <div v-loading="loading" style="min-height: 250px;">
            <div v-if="shoppingCartList.length == 0"
                style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                <img src="@/assets/wsj.png" style="width: 250px;object-fit: contain;" alt="">
                <div style="width: 100%;margin-top: 10px;">暂无数据</div>
            </div>
            <!-- 店铺 -->
            <div class="store" v-for="(i, index) in shoppingCartList" :key="index">
                <div class="storeHeader">
                    <div class="qx">
                        <div class="img" @click="selectCilck(index)">
                            <img v-show="i.select" src="@/assets/icon/xuanz.png" alt="">
                            <img v-show="!i.select" src="@/assets/icon/wxz.png" alt="">
                        </div>
                        <div class="text">{{ i.storeName }}</div>
                    </div>
                    <div class="yhq" @click="yhqListClick(i)">
                        <div class="text">优惠券</div>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <div class="con" v-for="(it, ind) in i.mallOrderItemVos" :key="ind">
                    <div class="img_name">
                        <div class="img" @click="selectShoppCilck(index, ind)">
                            <img v-show="it.selectShopp" src="@/assets/icon/xuanz.png" alt="">
                            <img v-show="!it.selectShopp" src="@/assets/icon/wxz.png" alt="">
                        </div>
                        <div class="shool_img">
                            <img :src="it.image" alt="">
                        </div>
                        <div style="display: flex;flex-wrap: wrap;width: 300px;">
                            <div class="name">{{ it.productName }}</div>
                            <div class="price">￥{{ it.price }}</div>
                        </div>
                    </div>
                    <div class="specifications">
                        <div v-for="(ide, inde) in it.skuValues" :key="inde">{{ ide.detailName }}</div>
                    </div>
                    <div class="num">
                        <el-input-number v-model="it.num" :min="1" :max="10" label="数量"
                            style="width: 130px;"></el-input-number>
                    </div>
                    <div class="danj">¥{{ formatDecimal((it.price * it.num)) }}</div>
                    <div class="del" @click="dele(it)">删除</div>
                </div>
            </div>
        </div>

        <!-- 结算 -->
        <div class="settlement">
            <div class="l" @click="delAllCilck">
                <div class="img">
                    <img src="@/assets/icon/xuanz.png" alt="">
                    <!-- <img src="@/assets/icon/wxz.png" alt=""> -->
                </div>
                <div class="text">删除选中的商品</div>
            </div>
            <div class="r">
                <div class="yyuan">已选择 <span> {{ numCat }}件 </span> 商品共 <span>{{ numG }}件</span></div>
                <div class="zjia">全部总价：<span>¥{{ totalPrice }}</span></div>
                <div class="btn" @click="payment">去结算</div>
            </div>
        </div>


        <!-- 抽屉优惠券 -->
        <el-drawer title="优惠券" :visible.sync="drawer" :with-header="true">
            <!-- 优惠券列表 -->
            <div class="yhqList">
                <div class="son" v-for="(item, index) in ListOfCoupons" :key="index">
                    <div class="l">
                        <div class="jiag" v-if="item.type == '1' || item.type == '4'"> ￥<span>{{ item.reduceAmount ||
                            item.fixationAmount }}</span> </div>
                        <div class="jiag" v-if="item.type == '3'"> ￥<span>{{ item.fixationAmount }}</span> </div>
                        <div class="jiag" v-if="item.type == '2'"> <span>{{ item.discount }}</span> 折</div>
                        <div class="text">满{{ item.premiseAmount }}可用</div>
                    </div>
                    <div class="r">
                        <div class="name">{{ item.storeName }}</div>
                        <div class="text" v-show="item.suitType == '1'">全部商品可用</div>
                        <div class="text" v-show="item.suitType == '2'">指定商品可用</div>
                        <div class="btn" v-show="!item.couponUser && !item.couponTname" @click="claimCouponsClick(item)">
                            立即领券</div>
                        <div class="btn btn3" v-show="item.couponUser || item.couponTname">已领取</div>
                        <div class="prompt">领取后{{ item.validDays }}天内有效</div>
                    </div>
                    <img src="@/assets/mjq.png" v-if="item.type == 1" alt="">
                    <img src="@/assets/jeq.png" v-if="item.type == 3" alt="">
                    <img src="@/assets/kmq.png" v-if="item.type == 4" alt="">
                    <img src="@/assets/zkq.png" v-if="item.type == 2" alt="">
                </div>
                <div v-if="ListOfCoupons.length == 0"
                style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                <img src="@/assets/yhq.png" style="width: 100px;object-fit: contain;" alt="">
                <div style="width: 100%;margin-top: 10px;">暂无优惠券</div>
            </div>
            </div>
        </el-drawer>

        <!-- 底部 -->
        <bottomContent></bottomContent>
    </div>
</template>

<script>

import { getAShoppingCart, deleteShoppingCartOfGoods } from "@/utils/api/myApi/index"
import { getListOfCouponsGWC, postClaimCoupons } from "@/utils/api/homeApi/index"
import myHead from "@/components/myHead.vue";
import bottomContent from "@/components/bottomContent.vue";
export default {
    name: 'myShoppingCart',
    components: {
        myHead,
        bottomContent
    },
    data() {
        return {
            drawer: false,
            num: 1,
            numCat: 0,
            // 购物车列表
            shoppingCartList: [],
            loading: true,
            // 全选
            allSelect: true,
            // 总价
            totalPrice: 0,
            // 可用优惠券
            ListOfCoupons: [],
            // 商家id
            storeId: '',
            // 商品id
            spuIds: '',
            numG:0
        }
    },
    mounted() {
        this.getAShoppingCartList()
    },
    watch: {
        shoppingCartList: {
            deep: true,
            handler(newVal, oldVal) {
                this.numCat = 0
                this.numG = 0
                for (let i = 0; i < this.shoppingCartList.length; i++) {
                    const cartItem = this.shoppingCartList[i];
                    const allSelected = cartItem.mallOrderItemVos.every(order => order.selectShopp === true);
                    if (allSelected) {
                        cartItem.select = true;
                    } else {
                        cartItem.select = false;
                    }
                }
                const allSelected2 = this.shoppingCartList.every(order => order.select === true);
                if (allSelected2) {
                    this.allSelect = true
                } else {
                    this.allSelect = false
                }
                let price = 0
                this.shoppingCartList.map(f => f.mallOrderItemVos.map(g => {
                    if (g.selectShopp) {
                        price = price + (g.num * g.price)
                        this.numCat++
                        this.numG += g.num
                    }
                }))
                this.totalPrice = this.formatDecimal(price)
            }
        },
    },
    methods: {
        // 领取优惠券
        async claimCouponsClick(val) {
            console.log(val)
            const res = await postClaimCoupons({
                id: val.id,
                storeId: val.storeId
            })
            console.log(res)
            if (res.statusCode == 8201) {
                this.$message.success('领取成功');
                this.getAvailableCoupons(this.storeId, this.spuIds)
            } else if (res.statusCode == 7001) {
                this.$message.error(res.message);
            } else {
                this.$message.error('领取失败');
            }
        },
        // 打开抽屉
        yhqListClick(i) {

            let spuIds = []
            i.mallOrderItemVos.map(item => {
                if (item.selectShopp) {
                    spuIds.push(item.spuId)
                }
            });
            this.storeId = i.storeId
            this.spuIds = spuIds.join(',')
            // console.log(spuIds.join(','))
            if (!spuIds.join(',')) return this.$message.error('请选择当前店铺商品')
            this.drawer = true
            this.getAvailableCoupons(i.storeId, spuIds.join(','))
        },
        // 获取可用优惠券
        async getAvailableCoupons(storeId, spuIds) {
            const res = await getListOfCouponsGWC({
                share: 1,
                current: 1,
                size: 100,
                storeId: storeId,
                spuIds: spuIds
            })
            if (res.statusCode == 8201) {
                this.ListOfCoupons = res.data.records

            }
        },
        // 获取购物车列表
        async getAShoppingCartList() {
            const res = await getAShoppingCart()
            if (res.statusCode == 8201) {
                this.shoppingCartList = res.data
                this.loading = false
                this.shoppingCartList.map(m => {
                    this.$set(m, 'select', true)
                    m.mallOrderItemVos.map(d => {
                        this.$set(d, 'selectShopp', true)
                    })
                })
            }
        },
        // 全选按钮
        allSelectCilck() {
            this.allSelect = !this.allSelect;
            for (let i = 0; i < this.shoppingCartList.length; i++) {
                const cartItem = this.shoppingCartList[i];
                cartItem.select = this.allSelect;
                for (let j = 0; j < cartItem.mallOrderItemVos.length; j++) {
                    const orderItem = cartItem.mallOrderItemVos[j];
                    orderItem.selectShopp = this.allSelect;
                }
            }
            console.log(this.shoppingCartList);
        },
        // 店铺选择按钮
        selectCilck(index) {
            this.shoppingCartList[index].select = !this.shoppingCartList[index].select
            if (this.shoppingCartList[index].select) {
                this.shoppingCartList[index].mallOrderItemVos.map(d => {
                    d.selectShopp = true
                })
            } else {
                this.shoppingCartList[index].mallOrderItemVos.map(d => {
                    d.selectShopp = false
                })
            }
            if (this.shoppingCartList.every(item => item.select === true)) {
                this.allSelect = true
            } else {
                this.allSelect = false
            }
        },
        // 商品选择按钮
        selectShoppCilck(index, ind) {
            this.shoppingCartList[index].mallOrderItemVos[ind].selectShopp = !this.shoppingCartList[index].mallOrderItemVos[ind].selectShopp
        },
        // 保留两位小数
        formatDecimal(value) {
            return value.toFixed(2);
        },
        // 删除全部所选商品
        delAllCilck() {
            this.$confirm('确认删除该商品吗?', '提示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let storeCartId = []
                this.shoppingCartList.map(d => d.mallOrderItemVos.map(b => {
                    if (b.selectShopp) {
                        storeCartId.push(b)
                    }
                }))
                this.deleStore(storeCartId.map(obj => obj.storeCartId).join(','))

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        // 删除单个商品
        dele(item) {
            this.$confirm('确认删除该商品吗?', '提示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deleStore(item.storeCartId)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        // 删除购物车商品
        async deleStore(storeCartId) {
            const res = await deleteShoppingCartOfGoods(storeCartId)
            if (res.statusCode == 8201 && res.data) {
                this.$message({
                    type: 'success',
                    message: '删除成功'
                });
                this.getAShoppingCartList()
            } else {
                this.$message({
                    type: 'error',
                    message: '删除失败'
                });
            }
        },
        payment() {
            console.log(this.shoppingCartList)
            const updatedList = this.shoppingCartList.map(item => {
                const filteredMallOrderItemVos = item.mallOrderItemVos.filter(v => v.selectShopp);
                return {
                    ...item,
                    mallOrderItemVos: filteredMallOrderItemVos
                };
            }).filter(item => item.mallOrderItemVos.length > 0);
            console.log(updatedList)
            if (!updatedList.length) return this.$message.error("请选择商品")
            localStorage.setItem('mallStoreObjGWC', JSON.stringify(updatedList))
            window.open('#/payment?type=GWC', '_blank');
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    >.header {
        width: 1200px;
        height: 68px;
        margin: 20px auto;
        background-color: #fff;
        padding: 0 17px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        >div {
            font-size: 14px;
            color: #333;
        }

        >.qx {
            width: 140px;
            display: flex;
            align-items: center;

            >.img {
                width: 16px;
                height: 16px;
                cursor: pointer;
                margin-right: 15px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        >.sp {
            width: 580px;
        }

        >.sl,
        .je {
            width: 160px;
        }
    }

    .store {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        background-color: #fff;
        box-sizing: border-box;

        >.storeHeader {
            width: 100%;
            height: 65px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(153, 153, 153, 0.50);

            >.qx {
                width: auto;
                display: flex;
                align-items: center;
                margin-left: 17px;

                >.img {
                    width: 16px;
                    height: 16px;
                    margin-right: 15px;
                    cursor: pointer;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            >.yhq {
                width: auto;
                display: flex;
                align-items: center;
                margin-right: 17px;
                cursor: pointer;

                >.text {
                    font-size: 16px;
                    color: #E1241B;
                }

                >i {
                    color: #E1241B;
                    margin-top: 2px;
                    margin-left: 10px;
                }
            }
        }

        >.con {
            width: 100%;
            // height: 180px;
            height: auto;
            padding: 45px 17px;
            box-sizing: border-box;
            display: flex;

            >.img_name {
                width: 520px;
                display: flex;

                >.img {
                    width: 16px;
                    height: 16px;
                    cursor: pointer;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                >.shool_img {
                    width: 94px;
                    height: 94px;
                    margin-left: 22px;
                    margin-right: 10px;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .name {
                    width: 300px;
                    font-size: 14px;
                    color: #333;
                    display: flex;
                    align-items: center;
                }
                .price {
                    font-size: 14px;
                    color: #333;
                    margin-top: 10px;
                }
            }

            >.specifications {
                width: 170px;
                height: auto;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #333;

                >div {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            >.num {
                width: 180px;
                display: flex;
                align-items: center;
            }

            >.danj {
                width: 170px;
                font-size: 16px;
                color: #333;
                display: flex;
                align-items: center;
            }

            >.del {
                font-size: 16px;
                color: #999;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    >.settlement {
        width: 1200px;
        height: 68px;
        margin: 20px auto;
        background-color: #fff;
        padding: 0 17px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >.l {
            display: flex;
            align-items: center;
            cursor: pointer;

            >.img {
                width: 16px;
                height: 16px;
                cursor: pointer;
                margin-right: 15px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            >.text {
                font-size: 14px;
                color: #999;
            }
        }

        >.r {
            display: flex;

            >.yyuan {
                font-size: 14px;
                color: #333;
                margin-right: 62px;
                display: flex;
                align-items: center;

                >span {
                    color: #E1241B;
                }
            }

            >.zjia {
                font-size: 14px;
                color: #333;
                margin-right: 46px;
                display: flex;
                align-items: center;

                >span {
                    font-size: 16px;
                    font-weight: bold;
                    color: #E1241B;
                }
            }

            >.btn {
                width: 122px;
                height: 68px;
                background: #E1241B;
                font-size: 24px;
                color: #fff;
                text-align: center;
                line-height: 68px;
                cursor: pointer;
            }
        }

    }
}

// 抽屉
.yhqList {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;

    >.son {
        width: 330px;
        height: 120px;
        margin-top: 20px;
        background: url(../../assets/lqyhbg.png);
        background-size: 100% 100%;
        display: flex;
        position: relative;

        >.l {
            width: 120px;
            height: 120px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            >.jiag {
                color: #FF4242;
                font-size: 16px;

                >span {
                    font-size: 30px;
                    font-weight: bold;
                }
            }

            >.text {
                font-size: 12px;
                color: #666;
                margin-top: 5px;
            }
        }

        >.r {
            width: 210px;
            height: 120px;
            padding: 20px 10px;
            box-sizing: border-box;
            box-sizing: border-box;

            >.name {
                font-size: 14px;
                color: #333;
            }

            >.text {
                font-size: 12px;
                color: #999;
                margin-top: 2px;
            }

            >.btn {
                width: 71px;
                height: 24px;
                border-radius: 18px;
                background-color: #FF4242;
                color: #fff;
                cursor: pointer;
                text-align: center;
                line-height: 24px;
                font-size: 14px;
                margin-left: 100px;
                margin-top: 0px;
            }

            >.btn2 {
                background-color: #999999;
            }

            >.btn3 {
                border: 1px solid #FF4242;
                background-color: rgba(255, 66, 66, .2);
                color: #FF4242;
            }


            >.prompt {
                color: #999;
                font-size: 12px;
                margin-left: 90px;
                margin-top: 5px;
            }
        }

        >img {
            width: auto;
            height: 18px;
            position: absolute;
            top: 4px;
            left: 8px;
        }
    }
}

/deep/ .el-drawer__open .el-drawer.rtl {
    width: 370px !important;
}

/deep/ .el-drawer {
    width: 370px !important;
}
</style>